.jumbotron{
  background-image: url('/backgroundmarconet.jpg');
  background-size: cover;
  background-position: center;
  background-color: rgb(115, 128, 137);
  height: 100%;
}

.grid-divider {
  overflow-x: hidden; /* quickfix to hide divider on left side */
  position: relative;
}

.grid-divider > [class*="col-"]:nth-child(n + 2):after {
  content: "";
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
}

.description {
  /* align-content: right;
  text-align: right; */
  font-size: 1.4rem;
}

.alt-background {
  background-color: #F4F2EC;
}

h1.jumboheadline {
  /*align-content: left;
  text-align: left; 
  font-size: 0.6em;*/
  font-size: 3rem;
  font-weight: 500; 
}

.row-color:nth-child(even) { 
    background-color:  #F4F2EC; 
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  background-color: var(--light); 
  border-bottom: 0px solid #e6e2de;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

@media screen and (max-width: 575px) {
  .grid-divider > [class*="col-"]:nth-child(2):after {  
    width: 80%;
    height: 2px;
    left: 10%;
    top: -4px; /* quickfix to center line between columns */
  }
}

@media screen and (min-width: 576px) {
  .grid-divider > [class*="col-"]:nth-child(2):after {    
    width: 2px;
    height: auto;
    right: -1px; /* quickfix to hide divider on left side */
  }  
}

.footer { 
  width:100%; 
  background-color: var(--light); 
  min-height:250px; 
  padding:30px 0px 30px 0px ;}

.footer .nav-link {
  display: block;
  padding: .2rem 1rem;
}

.footer a.nav-link {
  color: var(--dark);
  text-decoration: none;
  background-color: transparent;
}

.footer a.nav-link:hover{ 
  color: var(--gray); 
  text-decoration:none;
}

.footer_ul { margin:0px ; 
  list-style-type:none ;
   font-size:14px; 
   padding:0px 0px 10px 0px ; 
  }
  
.footer_ul li {
  padding:0px 0px 5px 0px;
}
.footer_ul li a{ 
  color: var(--dark);
}
.footer_ul li a:hover{ 
  color:var(--gray); 
  text-decoration:none;
}

.footer p {
  color: var(--dark);
  padding-bottom: 0px;
  margin-bottom: 8px;
}

.btn.buttonInTbl {
  text-align: right;
}

.version-info {
  color:var(--white);  
  font-size: smaller;
  text-align: right;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


/* tableview */
tbody a {
  text-decoration: none;
    width: 100%;
    margin-bottom: 1rem;
    color: #211e1b;
}
/* Chat */

.ChatWindow {
  overflow-y: scroll;
  max-height: 300px;
}


#myInput {
  /*background-image: url('/css/searchicon.png');  Add a search icon to input */
  background-position: 10px 12px; /* Position the search icon */
  background-repeat: no-repeat; /* Do not repeat the icon image */
  width: 100%; /* Full-width */
  font-size: 16px; /* Increase font-size */
  padding: 12px 20px 12px 40px; /* Add some padding */
  border: 1px solid var(--light); /* Add a grey border */
  margin-bottom: 12px; /* Add some space below the input */
}

#myUL {
  /* Remove default list styling */
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: none;
  
}

#myUL li a {
  border: 1px solid var(--light);  /* Add a border to all links */
  margin-top: -1px; /* Prevent double borders */
  background-color: var(--light);  /* Grey background color */
  padding: 12px; /* Add some padding */
  text-decoration: none; /* Remove default text underline */
  font-size: 18px; /* Increase the font-size */
  color: black; /* Add a black text color */
  display: block; /* Make it into a block element to fill the whole list */
}

#myUL li a:hover:not(.header) {
  background-color: #eee; /* Add a hover effect to all links, except for headers */
}

.leftSpan {
text-align: left;
}

.rightSpan {
text-align: right;
float: right;
}

 ul.Chat {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.Chat li.him, ul.Chat li.me{
  display:inline-block;
  clear: both;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 2px;
}

li.me span {
  float: right;
}

li.him span {
  float: right;
}

.him{
  background: var(--light); 
  float: left;
}

.me{
  float: right;
  background: var(--primary); 
  color: #fff;
}

li.himinfo {
  background: white; 
  float: left;
  clear: both;
}

li.meinfo{
  background: white; 
  float: right;
  clear: both;
}

.me{
  float: right;
  background: var(--primary); 
  color: #fff;
}

.him + .me{
  border-bottom-right-radius: 5px;
}

.me + .me{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.me:last-of-type {
  border-bottom-right-radius: 30px;
}